import React from 'react'
import styled from 'styled-components'

import Screens from 'theme/screens'
import { useText } from 'texts'
import { extractTerms } from 'utils/functions/extractTerms'
import Term from './Term'
const { t } = useText()

const terms = extractTerms(t('termsOfUseMagalu.terms'));

const buildTerms = (terms, isSubterm, tKey, parentTermIndex) =>
  terms.map((term, index) => {
    let indexString = index + 1
    let termIndex = parentTermIndex ? `${parentTermIndex}.${indexString}` : indexString
    if (typeof term === 'string' || term instanceof String) return (
      <Term
        term={`${tKey}.${term}`}
        isSubterm={true}
        highlight={index % 2 === 0}
        termIndex={termIndex}
      />
    )

    if ('subterms' in term) {
      let children = buildTerms(term.subterms, true, `${tKey}.${term.tKey}.${term.subtermsKey}`, termIndex)
      return (
        <Term
          children={children}
          term={`${tKey}.${term.tKey}.${term.titleKey}`}
          isSubterm={isSubterm}
          highlight={index % 2 === 0}
          termIndex={termIndex}
        />
      )
    }

  })

const StyledTermsOfUse = styled.div`
  width: 100%;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 20%;
  padding-right: 20%;
  z-index: 1;

  @media (max-width: ${Screens.md}) {
    min-width: auto;
  }

  @media (max-width: ${Screens.sm}) {
    position: relative;
    max-width: 100%;
    padding-right: 20%;
    align-items: center;
    text-align: center;
  }
`

const TermsContainer = styled.div`
  width: 100%;
`
const TermsOfUse = () => {
  const { t } = useText()

  return (
    <StyledTermsOfUse>
      <TextContainer>
        <h2 className="text-primary-100">{t('termsOfUseMagalu.hero.title')}</h2>
      </TextContainer>
      <TextContainer>
        <h3 className="text-primary-100">{t('termsOfUseMagalu.disclaimer.title')}</h3>
        <div>{t('termsOfUseMagalu.disclaimer.description')}</div>
      </TextContainer>
      <TermsContainer>
        {buildTerms(terms, false, 'termsOfUseMagalu.terms', '')}
      </TermsContainer>
    </StyledTermsOfUse>
  )
}

export default TermsOfUse
